import ListOut from '@components/shared/ListOut'
import useT from '@hooks/useTranslation'
import defaultStyles from 'styles/Select.module.css'

const Select = ({
    defaultOption,
    emptyText = 'Wybierz',
    inject,
    label = '',
    name,
    onChange,
    options,
    selectStyle = {},
    styles = defaultStyles,
    ...rest }) => {

    return (
        <label {...rest}>
            { hasLabel(label) && <span className={styles.label}>{inject}{ useT(label) }</span> }
            <select {...{name, onChange, defaultValue: defaultOption, className: styles.select, style: selectStyle}}>
                { emptyText && <option value="">{useT(emptyText)}...</option> }
                <ListOut
                    id="t"
                    items={options}
                    Component={Option}
                />
            </select>
        </label>
    )
}

function Option ({ t, value }) {
    return <option value={(value==null?t:value)}>{useT(t)}</option>
}

function hasLabel (label) {
    return Boolean((label || '').trim())
}

export default Select