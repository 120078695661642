"use client"

import { useRouter, usePathname } from 'next/navigation'
import { stringifyQuery, useSearchParamsAsQuery } from '@hooks/useSearchParamsAsQuery'

const useRouterCallback = (queryField, resetPage = true) => {
    const router = useRouter()
    const pathname = usePathname()
    const query = useSearchParamsAsQuery()
    const onChange = ({target:{value}}) => {
        if (resetPage) delete query['strona']
        if (value) query[queryField] = value
        else delete query[queryField]

        router.push([pathname, stringifyQuery(query)].filter(Boolean).join('?'))
    }

    return onChange
}



export default useRouterCallback