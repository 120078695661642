export default function searchFromObject (data, mapValue, _encode) {
    const string = Object.entries(data)
        .reduce((acc, pair) => {
            const [key, val] = pair

            const include = key && val && val !== 'Wszystkie'
            if (!include) return acc

            const isCheckbox = typeof val === 'boolean' || val === 'on'
            const isArray = Array.isArray(val)

            let finalValue

            const valueMapping = mapValue && mapValue[key]
            const mappedValue = valueMapping && val
                ? valueMapping[val] || val
                : val

            if (isCheckbox) {
                finalValue = `${encode(key, _encode)}=true`
            } else if (isArray) {
                finalValue = `${encode(key, _encode)}=${encodeArray(mappedValue, _encode)}`
            } else {
                finalValue = `${encode(key, _encode)}=${encode(mappedValue, _encode)}`
            }

            acc.push(finalValue)
            return acc

        }, [])
        .join('&')

    return string.length > 0
        ? `?${string}`
        : ''
}

function encode (val, _encode) {
    const v = val?.trim ? val.trim() : val
    return _encode ? encodeURIComponent(v) : v
}

function encodeArray (arr, _encode) {
    return arr.map(val => encode(val, _encode)).join(',')
}