"use client"

import { useSearchParamsAsQuery } from '@hooks/useSearchParamsAsQuery'
import Select from '@components/select/Select'
import useRouterCallback from '@hooks/useRouterCallback'
import SORT_OPTIONS, { NO_LOCATION_SORT_OPTIONS } from '@components/select/values/sortOptions'
import withUser from '@hoc/withUser'

const SORT_NAME = 'sortuj'

const Sort = ({ isLoggedIn, label, user, ...rest }) => {
    const query = useSearchParamsAsQuery()
    const onChange = useRouterCallback(SORT_NAME)
    const enableGeoSort = Boolean(user?.location)
    return <Select
        defaultOption={query[SORT_NAME]}
        label={label !== null ? "Sortuj" : label}
        name={SORT_NAME}
        onChange={onChange}
        options={enableGeoSort
            ? SORT_OPTIONS
            : NO_LOCATION_SORT_OPTIONS}

        {...rest}
    />
}

export default withUser(Sort)