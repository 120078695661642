import {
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import Button from '@components/button/Button'
import Sort from '@components/select/Sort'
import useMediaQueryBreakpoint from '@hooks/useMediaQueryBreakpoint'
import useT from '@hooks/useTranslation'
import mix from '@utils/styles/mix'
import BREAKPOINTS from '@constants/breakpoints'

import styles from './SearchFormContainer.module.css'
import useLocalStorage from '@hooks/useLocalStorage'

const MobileToggleContext = createContext({
	onMobile: false,
	setShow: () => null,
	show: false,
})
const useMobileToggleContext = () => useContext(MobileToggleContext)

function SearchFormContainer({ children, className, ...rest }) {
	return (
		<div className={mix([styles.container, className])} {...rest}>
			{children}
		</div>
	)
}

function FormPocket({ children, className, ...rest }) {
	return (
		<div
			className={mix([styles['form-pocket'], className])}
			{...rest}
		>
			{children}
		</div>
	)
}

function ContentPocket({ children, className, ...rest }) {
	return (
		<div
			className={mix([styles['content-pocket'], className])}
			{...rest}
		>
			{children}
		</div>
	)
}

function StateProvider({
	breakpoint = BREAKPOINTS.showHamburger,
	children,
}) {
	const [filtry, setFiltry] = useLocalStorage('filtry')

	const [show, setShow] = useState(Boolean(filtry))
	const onMobile = useMediaQueryBreakpoint(breakpoint)

	const contextValues = useMemo(
		() => ({
			onMobile,
			show,
			setShow,
		}),
		[onMobile, show, setShow],
	)

	useEffect(() => {
		if (filtry) {
			setFiltry('')
		}
	}, [filtry, setFiltry])

	return (
		<MobileToggleContext.Provider value={contextValues}>
			{children}
		</MobileToggleContext.Provider>
	)
}

function MobileToggle({
	before,
	children,
	sort,
	useAllways,
	...rest
}) {
	const { show, onMobile } = useMobileToggleContext()
	const button = show ? undefined : (
		<MobileToggleButton {...rest} sort={sort} teal />
	)

	return (
		<MobileToggleContainer
			before={before}
			button={button}
			use={useAllways || onMobile}
		>
			{children}
		</MobileToggleContainer>
	)
}

function MobileToggleButton({
	icon = 'tune',
	sort,
	text,
	useSort = true,
	scrollWindow = true,
	...rest
}) {
	const { show, setShow } = useMobileToggleContext()
	const onClick = () =>
		setShow((show) => {
			if (!show && scrollWindow) window.scrollTo(0, 0)
			return !show
		})

	const t = {
		filter: useT('Filtruj'),
		text: useT(text || ''),
	}

	return (
		<div className={styles['toggle-form-actions']}>
			<Button
				data-filter-show={show}
				icon={icon}
				onClick={onClick}
				{...rest}
			>
				{text ? t.text : t.filter}
			</Button>
			{useSort && (sort || <Sort style={{ marginLeft: 'auto' }} />)}
		</div>
	)
}

function MobileToggleContainer({ before, button, use, children }) {
	const { show } = useMobileToggleContext()

	if (!use) return children

	return (
		<div className={styles['toggle-form']}>
			{before && (
				<>
					{before}
					<hr />
				</>
			)}

			{button}

			<div
				className={mix([styles['toggled-form'], show && styles.show])}
			>
				{children}
			</div>
		</div>
	)
}

function StickySubmitContainer({ children, ...rest }) {
	return (
		<div className={styles['sticky-bottom']} {...rest}>
			{children}
		</div>
	)
}

function StickToTopWhenFiltersClosed({ children, ...rest }) {
	const { show } = useMobileToggleContext()

	return (
		<div
			className={show ? undefined : styles['sticky-top']}
			{...rest}
		>
			{children}
		</div>
	)
}

SearchFormContainer.ContentPocket = ContentPocket
SearchFormContainer.FormPocket = FormPocket
SearchFormContainer.MobileToggle = MobileToggle
SearchFormContainer.MobileToggleButton = MobileToggleButton
SearchFormContainer.StickySubmitContainer = StickySubmitContainer
SearchFormContainer.StickToTopWhenFiltersClosed =
	StickToTopWhenFiltersClosed
SearchFormContainer.StateProvider = StateProvider

export default SearchFormContainer
