import { useSearchParams } from 'next/navigation'

function stringifyQuery (query) {
    return Object.entries(query).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
}

function useSearchParamsAsQuery () {
    const searchParams = useSearchParams()
    return [...(searchParams?.keys() || [])].reduce((acc, key) => {
        const val = searchParams.get(key)
        if (key && val) acc[key] = val
        return acc
    }, {})
}

export {
    stringifyQuery,
    useSearchParamsAsQuery,
}