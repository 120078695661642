"use client"

import Head from 'next/head'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useSearchParamsAsQuery } from '@hooks/useSearchParamsAsQuery'
import searchFromObject from '@utils/links/searchFromObject'
import Icon from '@components/shared/Icon'
import mix from '@utils/styles/mix'
import useT from '@hooks/useTranslation'

import styles from './Pagination.module.css'

const HOST = process.env.NEXT_PUBLIC_HOST

const RouterPagination = ({
    atBottom = true,
    children,
    onTop = true,
    ...rest
}) => {

    const pathname = usePathname()
    const query = useSearchParamsAsQuery()

    const perPageSetting = rest.perPageSetting
    const perPage = rest.perPage
    const defaultPerPage = (!perPageSetting || perPage === rest.defaultPerPage) ? undefined : perPage
    const link = (page, perPage = defaultPerPage) => createLink(pathname, query, page, perPage)

    const buttons = <Buttons link={link} {...rest} />

    return (
        <>
            { onTop && buttons }
            { children }
            { atBottom && buttons }
        </>
    )
}

function Buttons ({
    link,
    onEachSide = 2,
    page,
    perPage,
    perPageOptions = [5, 20, 50, 100, 150],
    perPageSetting = false,
    totalPages = 0, // totalDocs
    scrollToId,
}) {

    const t = {
        po: useT('Ofert na stronie'),
    }

    let start = page - onEachSide
    let end = page + onEachSide

    if (start < 1) {
        end += (start * -1) + 1
        start = 1
    }

    if (end > totalPages) {
        end = totalPages
    }

    const buttons = []
    for (let i = start; i <= end; i++) {
        buttons.push(<Button
            active={i !== page}
            current={i === page}
            key={i}
            link={link}
            page={i}
            scrollToId={scrollToId}
        />)
    }

    const gapLeft = start > 1
    const gapRight = end < totalPages
    const hasPerPageSettings = perPageOptions && perPageSetting
    const className = mix([styles.buttons, !hasPerPageSettings && styles.center])
    return (
        <nav className={className}>
            { hasPerPageSettings && <div className={styles['per-page']}>
                <span>{t.po}:</span>
                {
                    perPageOptions.map(count => {
                        const isActive = count == perPage
                        const className = isActive ? styles.active : undefined
                        const href = isActive ? '#' : link(1, count)
                        return (
                            <Link href={href} key={count} className={className}>
                                {count}
                            </Link>
                        );
                    })
                }
            </div> }

            { totalPages > 1 && <>
                { page !== 1 && <Previous {...{page, link, scrollToId}} /> }

                { gapLeft && <>
                    <Button
                        active={page !== 1}
                        current={page === 1}
                        link={link}
                        page={1}
                        scrollToId={scrollToId}
                    />
                    <p>...</p>
                </> }

                { buttons }

                { gapRight && <>
                    <p>...</p>
                    <Button
                        active={page !== totalPages}
                        current={page === totalPages}
                        link={link}
                        page={totalPages}
                        scrollToId={scrollToId}
                    />
                </> }

                { page !== totalPages && <Next {...{page, totalPages, link, scrollToId}} /> }
            </> }
        </nav>
    );
}

function Button ({ active, alt, current, display, link, page, scrollToId }) {
    const className = active
        ? styles.button
        : current
            ? `${styles.button} ${styles.current}`
            : `${styles.button} ${styles.disabled}`

    alt = alt || `Strona nr. ${page}`

    const scrollTo = scrollToId
        ? (() => {
            document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' })
          })

        : undefined

    return active
        ? <Link
            href={link(page)}
            scroll={scrollToId ? false : true}
            className={className}
            onClick={scrollTo}
            title={alt}
          >

            {display || page}

          </Link>

        : <div className={className}>
                {display || page}
          </div>;
}

function Previous ({ page, link, scrollToId }) {
    const active = page > 1
    const _page = active ? page - 1 : undefined
    return <>
        { active &&
            <PaginationHeadLink _key="pagi-prev" rel="prev" href={link(_page)} /> }
        <Button {...{
            active,
            alt: 'Poprzednia strona',
            display: <Icon>chevron_left</Icon>,
            link,
            page: _page,
            scrollToId,
        }} />
    </>
}

function Next ({ page, totalPages, link, scrollToId }) {
    const active = page < totalPages
    const _page = active ? page + 1 : undefined
    return <>
        { active &&
            <PaginationHeadLink _key="pagi-next" rel="next" href={link(_page)} /> }
        <Button {...{
            active,
            alt: 'Następna strona',
            display: <Icon>chevron_right</Icon>,
            link,
            page: _page,
            scrollToId,
        }} />
    </>
}

function PaginationHeadLink ({ _key, href, rel }) {
    return <Head>
        <link key={_key} rel={rel} href={`${HOST}${href}`} />
    </Head>
}

function createLink (pathname, query, page, perPage) {
    if (page > 1) {
        query.strona = page
    } else {
        delete query.strona
    }

    if (perPage && perPage != 30) {
        query.po = perPage
    } else {
        delete query.po
    }

    return `${pathname}${searchFromObject(query)}`
}

export default RouterPagination